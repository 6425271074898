<template>
  <div class="content">
    <template>
      <v-card
        :style="
          $vuetify.breakpoint.xs
            ? 'margin: 4px'
            : 'margin: 0 18px 0 18px'
        "
      >
        <base-loading v-if="loading" />

        <div v-if="!loading">
          <crud-list
            v-model="categorias"
            :headers="headers"
            :sort-by="['nome']"
            :sort-desc="[false]"
            :slots="[
              'item.abrv',
            ]"
            export-pdf-xlsx
          >
            <template v-slot:[`item.abrv`]="{ item }">
              <v-icon
                :title="item.abrv"
                large
                color="blue darken-3"
              >
                mdi-{{ item.abrv }}
              </v-icon>
            </template>
          </crud-list>
        </div>
      </v-card>
    </template>
  </div>
</template>
<script>
  import CategoriasStore, { BOOTSTRAP, INATIVAR_CATEGORIA } from '@/store/modules/categorias'
  import { mapState, mapActions } from 'vuex'
  export default {
    data () {
      return {
        actions: [
          {
            title: 'Editar categoria',
            color: 'info darken-3',
            click: item => this.editarCategoria(item),
            icon: 'mdi-pencil',
          },
          {
            title: 'Excluir categoria',
            color: 'red darken-3',
            click: item => this.INATIVAR_CATEGORIA({ item }),
            icon: 'mdi-trash-can-outline',
          },
        ],
        headers: [
          { align: 'left', sortable: false, groupable: false, text: 'Icone', value: 'abrv', width: '40px' },
          { align: 'left', sortable: true, groupable: false, text: 'Nome', value: 'nome', width: 'auto', floatingfilter: true, report: true },
        ],
      }
    },
    computed: {
      ...mapState('categorias', ['loading', 'categorias']),
    },
    async created () {
      if (!this.$store.hasModule('categorias')) { this.$store.registerModule('categorias', CategoriasStore) }
      this.BOOTSTRAP()
    },
    methods: {
      ...mapActions('categorias', [BOOTSTRAP, INATIVAR_CATEGORIA]),
      editarCategoria ({ id }) {
        this.$router.push({
          path: `/${this.$user.get().role}/categorias/cadastro/`,
          query: { id },
        })
      },
    },
  }
</script>
